import React, { useEffect } from "react";
import "../css/default.scss";
import "../css/games.scss";

import GameData from "../types/GameData";
import LoadingText from "./loadingText";
import GameEntry from "./gameEntry";

const featuredGames : string[] = ['7 Days of Rain', 'Witches Path (Demo)', 'Welcome to the Drone Dome (Demo)', 
	'Puzzle Place', 'Starburst'];

const Games: React.FC<{ show: boolean, data: GameData[], fetchedData: boolean, errorOccured: boolean }> = 
({ show, data, fetchedData, errorOccured }) => {
	useEffect(() => {
		const SortData = () => {
			data.sort(function(a : GameData, b : GameData) {
				return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
			});
		}
		SortData();
	}, [data]);

	return (
		<div id="games" className="section padded-section" style={{display: show ? "block" : "none"}}>
			<p>Featured</p>
			<LoadingText doneLoading={fetchedData} success={!errorOccured} subject={"Games"}></LoadingText>
			<div className="games-list">
				{
					featuredGames.map((e1, i1) =>
					{
						const d : GameData | undefined = data.find((element) => element.title === e1);
						if (d === undefined)
						{
							return (null);
						} else 
						{
							return <GameEntry data={d} key={i1}></GameEntry> 
						}
					}	
				)}
			</div>

			<p>Archives</p>
			<LoadingText doneLoading={fetchedData} success={!errorOccured} subject={"Games"}></LoadingText>
			<div className="games-list">
				{data.map((element, index) => {
					return <GameEntry data={element} key={index}></GameEntry>
				})}
			</div>
		</div>
	);
};

export default Games;
